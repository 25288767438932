import React from "react"
import Article from "../components/article/Article"
import Banner from "../components/Banner"
import { graphql } from "gatsby"
import img from "../images/header-image-default.jpg"
import { FormattedMessage } from "react-intl"
import { DefaultHeader } from "../components/header"
import Layout from "../components/layout"
import Section from "../components/Section"
import SEO from "../components/seo"

const PricesPage = ({ data, pageContext: { locale } }) => {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout locale={locale}>
      <SEO title="Hostel Ljubas - prices" pathname="/prices/" />
      <DefaultHeader img={img}>
        <Banner
          title={<FormattedMessage id="prices">{txt => txt}</FormattedMessage>}
          subtitle=""
        />
      </DefaultHeader>
      <Section>
        <Article title={frontmatter.title}>{html}</Article>
      </Section>
    </Layout>
  )
}

export const pricesQuery = graphql`
  query($path: String) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`

export default PricesPage
